import React from 'react';
import { Link } from 'react-router-dom';
import MortgageCalculator from './MortgageCalculator';

const Area2 = () => (
  <div className='containerpage'>
    <Link to="/">
      <div className='head head2 '>
        <img src={require("./arrow-left.svg").default} alt='' />
        <strong>返回工具箱</strong>
      </div>
    </Link>
    <div className="App">
      <h2>个人贷款计算</h2>
      <MortgageCalculator />
    </div>
  </div>
);


export default Area2;