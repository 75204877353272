import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const RefinanceCalculator = () => {
  const [currentLoan, setCurrentLoan] = useState('1000000');
  const [currentRate, setCurrentRate] = useState('4.2');
  const [newRate, setNewRate] = useState('2.85');
  const [currentRemainingYears, setCurrentRemainingYears] = useState('20');
  const [currentRemainingMonths, setCurrentRemainingMonths] = useState('0');
  const [newRemainingYears, setNewRemainingYears] = useState('20');
  const [newRemainingMonths, setNewRemainingMonths] = useState('0');
  const [refinanceCost, setRefinanceCost] = useState('0');
  const [result, setResult] = useState(null);

  const formatNumber = (num) => {
    return new Intl.NumberFormat('zh-CN', { maximumFractionDigits: 2 }).format(num);
  };

  useEffect(() => {
    setNewRemainingYears(currentRemainingYears);
    setNewRemainingMonths(currentRemainingMonths);
  }, [currentRemainingYears, currentRemainingMonths]);

  const calculateSavings = () => {
    const loan = parseFloat(currentLoan);
    const oldRate = parseFloat(currentRate) / 100 / 12;
    const newRateMonthly = parseFloat(newRate) / 100 / 12;
    const currentMonths = parseInt(currentRemainingYears) * 12 + parseInt(currentRemainingMonths);
    const newMonths = parseInt(newRemainingYears) * 12 + parseInt(newRemainingMonths);
    const cost = parseFloat(refinanceCost);

    const calculatePayment = (principal, rate, months) => {
      return principal * (rate * Math.pow(1 + rate, months)) / (Math.pow(1 + rate, months) - 1);
    };

    const oldPayment = calculatePayment(loan, oldRate, currentMonths);
    const newPayment = calculatePayment(loan, newRateMonthly, newMonths);

    const oldTotalPayment = oldPayment * currentMonths;
    const newTotalPayment = newPayment * newMonths;
    const oldTotalInterest = oldTotalPayment - loan;
    const newTotalInterest = newTotalPayment - loan;

    const monthlySavings = oldPayment - newPayment;
    const totalSavings = oldTotalPayment - newTotalPayment - cost;

    setResult({
      oldPayment: oldPayment.toFixed(2),
      newPayment: newPayment.toFixed(2),
      monthlySavings: monthlySavings.toFixed(2),
      totalSavings: totalSavings.toFixed(2),
      oldTotalInterest: oldTotalInterest.toFixed(2),
      newTotalInterest: newTotalInterest.toFixed(2),
      oldTotalPayment: oldTotalPayment.toFixed(2),
      newTotalPayment: newTotalPayment.toFixed(2),
    });
  };

  const chartData = result ? [
    { name: '当前月供', 金额: parseFloat(result.oldPayment) },
    { name: '新月供', 金额: parseFloat(result.newPayment) },
  ] : [];

  return (
    <div className="calculator">
      <p>本工具基于等额本息还款方式，帮助您比较不同贷款方案的财务影响。</p>
      <p>主要用途:计算更换贷款后的每月还款变化，对比不同利率和还款年限下的总利息支出</p>
      <p>使用场景:考虑重新贷款时、评估不同银行的贷款产品、决策是否提前还款</p>
      <div className='line-2'></div>
      <div className="input-group">
        <p>当前贷款余额 (元)</p>
        <label>
          <input type="number1" value={currentLoan} onChange={(e) => setCurrentLoan(e.target.value)} placeholder="例如: 1000000" />
        </label>
      </div>
      <div className="input-group">
        <p>当前年利率 (%)</p>
        <label>
          <input type="number1" step="0.01" value={currentRate} onChange={(e) => setCurrentRate(e.target.value)} placeholder="例如: 4.2" />
        </label>
      </div>
      <div className="input-group">
        <p>当前剩余贷款期限（年+月）</p>
        <label>
          <input type="number" value={currentRemainingYears} onChange={(e) => setCurrentRemainingYears(e.target.value)} placeholder="年" />
          <input type="number" value={currentRemainingMonths} onChange={(e) => setCurrentRemainingMonths(e.target.value)} placeholder="月" />
        </label>
      </div>

      <div className="input-group">
        <p>新的年利率 (%)</p>
        <label>
          <input type="number1" step="0.01" value={newRate} onChange={(e) => setNewRate(e.target.value)} placeholder="例如: 2.9" />
        </label>
      </div>
      
      <div className="input-group">
        <p>新的剩余贷款期限（年+月）</p>
        <label>
          <input type="number" value={newRemainingYears} onChange={(e) => setNewRemainingYears(e.target.value)} placeholder="年" />
          <input type="number" value={newRemainingMonths} onChange={(e) => setNewRemainingMonths(e.target.value)} placeholder="月" />
        </label>
      </div>
      <div className="input-group">
        <p>再融资成本 (元)</p>
        <label>
          <input type="number1" value={refinanceCost} onChange={(e) => setRefinanceCost(e.target.value)} placeholder="例如: 10000" />
        </label>
      </div>
      <button onClick={calculateSavings}>计算差额</button>
      {result && (
        <div className="results">
          <h3>计算结果</h3>
          <div className='w-full'>
            <p className='flex-grow'>当前月供:</p>
            <p className='text-right'> ¥{formatNumber(result.oldPayment)}</p>
          </div>
          <div className='w-full'>
            <p className='flex-grow'>当前总利息:</p>
            <p className='text-right'> ¥{formatNumber(result.oldTotalInterest)}</p>
          </div>
          <div className='w-full'>
            <p className='flex-grow'>当前还款总额:</p>
            <p className='text-right'> ¥{formatNumber(result.oldTotalPayment)}</p>
          </div>

          <div className='line-1'></div>

          <div className='w-full'>
            <p className='flex-grow'>新月供:</p>
            <p className='text-right-2'> ¥{formatNumber(result.newPayment)}</p>
          </div>

          <div className='w-full'>
            <p className='flex-grow'>新的总利息:</p>
            <p className='text-right-2'> ¥{formatNumber(result.newTotalInterest)}</p>
          </div>

          <div className='w-full'>
            <p className='flex-grow'>新的还款总额:</p>
            <p className='text-right-2'> ¥{formatNumber(result.newTotalPayment)}</p>
          </div>

          <div className='line-1'></div>

          <div className='w-full'>
            <p className='flex-grow'>每月节省:</p>
            <p className='text-right-3'> ¥{formatNumber(result.monthlySavings)}</p>
          </div>

          <div className='w-full'>
            <p className='flex-grow'>总节省 (扣除再融资成本)</p>
            <p className='text-right-3'> ¥{formatNumber(result.totalSavings)}</p>
          </div>

          <div className='line-1'></div>

          <div className='h2024'>
            <h4>月供对比</h4>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={chartData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="金额" fill="#4C95DA" />
              </BarChart>
            </ResponsiveContainer>
          </div>

        </div>
      )}
    </div>
  );
};

export default RefinanceCalculator;