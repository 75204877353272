import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const Home = () => (
  <div className='containerpage'>
    <div className='head'>
      <img src={require("./logo.svg").default} alt='' />
      <Link to="/">阿布的工具箱</Link>
    </div>
    <div className='container'>
      <Link className='area area-02' to="/area2">
        <div>
          <img src={require("./Loan Calculation.svg").default} alt='' />
          <strong>个人贷款计算</strong>
        </div>
      </Link>
      <Link className='area area-01' to="/area1">
        <div>
          <img src={require("./AorB.svg").default} alt='' />
          <strong>贷款方案对比</strong>
        </div>
      </Link>
    </div>
  </div>
);

export default Home;