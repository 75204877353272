import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Area1 from './Area1';
import Area2 from './Area2';
import './App.css';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Area1" element={<Area1 />} />
      <Route path="/Area2" element={<Area2 />} />
    </Routes>
  </Router>
);

export default App;