import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MortgageCalculator = () => {
  const [principal, setPrincipal] = useState(1000000);
  const [annualRate, setAnnualRate] = useState(3.85);
  const [years, setYears] = useState(30);
  const [equalPayment, setEqualPayment] = useState({});
  const [equalPrincipal, setEqualPrincipal] = useState({});
  const [equalPaymentChartData, setEqualPaymentChartData] = useState([]);
  const [equalPrincipalChartData, setEqualPrincipalChartData] = useState([]);

  const formatNumber = (num) => {
    return new Intl.NumberFormat('zh-CN', { maximumFractionDigits: 2 }).format(num);
  };

  const calculateEqualPayment = (principal, annualRate, years) => {
    const monthlyRate = annualRate / 12 / 100;
    const numPayments = years * 12;
    const monthlyPayment = principal * (monthlyRate * Math.pow(1 + monthlyRate, numPayments)) / (Math.pow(1 + monthlyRate, numPayments) - 1);
    const totalPayment = monthlyPayment * numPayments;
    const totalInterest = totalPayment - principal;
    
    const chartData = [];
    let remainingPrincipal = principal;
    for (let i = 1; i <= numPayments; i++) {
      const interest = remainingPrincipal * monthlyRate;
      const principalPayment = monthlyPayment - interest;
      remainingPrincipal -= principalPayment;
      chartData.push({
        month: i,
        principalPayment: Number(principalPayment.toFixed(2)),
        interest: Number(interest.toFixed(2)),
        totalPayment: Number(monthlyPayment.toFixed(2))
      });
    }
    
    return { monthlyPayment, totalPayment, totalInterest, chartData };
  };

  const calculateEqualPrincipal = (principal, annualRate, years) => {
    const monthlyRate = annualRate / 12 / 100;
    const numPayments = years * 12;
    const monthlyPrincipal = principal / numPayments;
    
    let totalPayment = 0;
    let firstPayment, lastPayment;
    const chartData = [];

    for (let i = 0; i < numPayments; i++) {
      const remainingPrincipal = principal - i * monthlyPrincipal;
      const interest = remainingPrincipal * monthlyRate;
      const payment = monthlyPrincipal + interest;
      if (i === 0) firstPayment = payment;
      if (i === numPayments - 1) lastPayment = payment;
      totalPayment += payment;
      
      chartData.push({
        month: i + 1,
        principalPayment: Number(monthlyPrincipal.toFixed(2)),
        interest: Number(interest.toFixed(2)),
        totalPayment: Number(payment.toFixed(2))
      });
    }
    
    const totalInterest = totalPayment - principal;
    
    return { firstPayment, lastPayment, totalPayment, totalInterest, chartData };
  };

  useEffect(() => {
    const equalPaymentResult = calculateEqualPayment(principal, annualRate, years);
    setEqualPayment(equalPaymentResult);
    setEqualPaymentChartData(equalPaymentResult.chartData);

    const equalPrincipalResult = calculateEqualPrincipal(principal, annualRate, years);
    setEqualPrincipal(equalPrincipalResult);
    setEqualPrincipalChartData(equalPrincipalResult.chartData);
  }, [principal, annualRate, years]);

  const PaymentChart = ({ data, title }) => (
    <div className="chart-container">
      <h3>{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip formatter={(value) => formatNumber(value)} />
          <Legend />
          <Line type="monotone" dataKey="principalPayment" name="本金" stroke="#8884d8" />
          <Line type="monotone" dataKey="interest" name="利息" stroke="#82ca9d" />
          <Line type="monotone" dataKey="totalPayment" name="月还款总额" stroke="#ffc658" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div className="calculator">
      <p>输入贷款信息，自动计算等额本息和等额本金</p>
      <div className='line-2'></div>
      <div className="space-y-4 mb-4">
        <div className="input-group">
          <p>贷款总金额 (元)</p>
          <input
            type="number1"
            value={principal}
            onChange={(e) => setPrincipal(Number(e.target.value))}
            placeholder="贷款本金"
          />
        </div>
        <div className="input-group">
          <p>年利率 (%)</p>
          <input
            type="text"
            value={annualRate}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d*\.?\d*$/.test(value)) {
                setAnnualRate(value);
              }
            }}
            onBlur={() => setAnnualRate(Number(annualRate))}
            placeholder="年利率 (%)"
          />
        </div>
        <div className="input-group">
          <p>贷款期限（年）</p>
          <input
            type="number1"
            value={years}
            onChange={(e) => setYears(Number(e.target.value))}
            placeholder="贷款年限"
          />
        </div>
      </div>

      <div className='container2'>
        <div className='card'>
          <h3>等额本息</h3>
          <div className='w-full' >
            <p className='flex-grow'>每月还款: </p>
            <p className='text-right-3'>¥{formatNumber(equalPayment.monthlyPayment?.toFixed(2))}</p>
          </div>
          
          <div className='w-full' >
            <p className='flex-grow'>总还款额: </p>
            <p className='text-right'>¥{formatNumber(equalPayment.totalPayment?.toFixed(2))}</p>
          </div>

          <div className='w-full' >
            <p className='flex-grow'>总利息: </p>
            <p className='text-right-2'>¥{formatNumber(equalPayment.totalInterest?.toFixed(2))}</p>
          </div>
          <PaymentChart data={equalPaymentChartData} title="还款明细图" />
        </div>
        <div className='card' >
          <h3>等额本金</h3>

          <div className='w-full' >
            <p className='flex-grow'>首月还款额: </p>
            <p className='text-right-3'>¥{formatNumber(equalPrincipal.firstPayment?.toFixed(2))}</p>
          </div>

          <div className='w-full' >
            <p className='flex-grow'>末月还款额: </p>
            <p className='text-right-3'>¥{formatNumber(equalPrincipal.lastPayment?.toFixed(2))}</p>
          </div>

          <div className='w-full' >
            <p className='flex-grow'>总还款额: </p>
            <p className='text-right'>¥{formatNumber(equalPrincipal.totalPayment?.toFixed(2))}</p>
          </div>

          <div className='w-full' >
            <p className='flex-grow'>总利息: </p>
            <p className='text-right-2'>¥{formatNumber(equalPrincipal.totalInterest?.toFixed(2))}</p>
          </div>
          <PaymentChart data={equalPrincipalChartData} title="还款明细图" />
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;