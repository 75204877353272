import React from 'react';
import { Link } from 'react-router-dom';
import RefinanceCalculator from './RefinanceCalculator';

const Area1 = () => (
  <div className='containerpage'>
    <Link to="/">
      <div className='head head2 '>
        <img src={require("./arrow-left.svg").default} alt='' />
        <strong>返回工具箱</strong>
      </div>
    </Link>

    <div className="App">
      <h2>贷款方案对比</h2>
      <RefinanceCalculator />
    </div>
  </div>
);

export default Area1;